let step = 0;

function getStep() {
    return ++step;
}

export function getStepCount() {
    return step;
}

export class GuideHead {
    constructor({
        type = 'guide', // 类型
        padding = { v: 12, h: 14 }, // 目标偏移量, v:竖向 h:横向
        el, // 目标元素
        id, // id
        step,
        ...guideProps
    }) {
        this.type = type;
        this.padding = padding;
        this.selector = el;
        this.id = id;
        this.next = null;
        this.prev = null;
        this.step = step ? getStep() : null;
        this.guideProps = guideProps;
    }

    find(id) {
        let head = this;
        while (head) {
            if (head.id === id) {
                return head;
            }
            head = head.next;
        }

        return null;
    }

    findEnd() {
        let head = this;
        while (head.next) {
            head = head.next;
        }
        return head;
    }

    appendToEnd(head) {
        const latestHead = this.findEnd();
        latestHead.next = head;
        head.prev = latestHead;
    }

    delete(id) {
        const targetHead = this.find(id);
        if (targetHead) {
            if (targetHead.next) {
                targetHead.next.prev = targetHead.prev;
            }

            if (targetHead.prev) {
                targetHead.prev.next = targetHead.next;
            }

            // 如果删除的是自己，返回子节点
            if (targetHead === this) {
                return this.next;
            }
        }

        return this;
    }
}