import { unmountComponentAtNode } from 'react-dom';
import { eventBus } from './bus';
import { createRootControl } from './create-root';
import { GuideHead } from './head';
import { renderStep } from './render';
import { stopResizeWatcher } from './resize';
import { reviseElementRect } from './util';

const rootControl = createRootControl();
let currentGuide = null;

eventBus.on('error', err => console.warn('[Guide Plugin]', err));

eventBus.on('next', (guideHead) => {
    currentGuide = guideHead.next;
    if (currentGuide) {
        unmountComponentAtNode(rootControl.helperLayer);
        renderStep(rootControl, currentGuide);
    } else {
        stopResizeWatcher();
        rootControl.hide();
        eventBus.emit('end', guideHead);
    }
});

eventBus.on('skip-all', () => {
    rootControl.hide();
    eventBus.emit('end');
    currentGuide = null;
});

eventBus.on('window-resize', () => {
    if (currentGuide
        && currentGuide.type === 'guide'
        && rootControl.isVisible()
    ) {
        const targetEl = document.querySelector(currentGuide.selector);
        const { width, height, left, top } = reviseElementRect(
            targetEl,
            currentGuide.padding
        );

        rootControl.resetTargetRect({ width, height, left, top });
    }
});

/**
 * @typedef {{
 *   id: string,
 *   type: 'guide' | 'modal',
 *   padding: { v: number, h: number }
 *   el: string,
 *   step: boolean,
 *   placement?: string,
 *   title: string | function,
 *   content: string | function,
 *   prefix: function,
 *   footer: function,
 *   showSkip: boolean,
 *   nextBtnText: string
 * }} GuideOption
 * @param {Array<GuideOption>} options options
 */
export function registerGuides(options = []) {
    options.forEach(option => {
        const head = new GuideHead(option);
        if (!currentGuide) {
            currentGuide = head;
        } else {
            currentGuide.appendToEnd(head);
        }
    });
}

/**
 * 删除链表中指定节点
 * @param {string | number} id
 */
export function deleteGuide(id) {
    if (currentGuide) {
        currentGuide = currentGuide.delete(id);
    }
}

/**
 * 清空链表
 */
export function cleanGuides() {
    currentGuide = null;
}

/**
 * 开始
 */
export function runGuide() {
    if (!currentGuide) {
        return;
    }

    // 先清理一下挂载的弹窗组件
    unmountComponentAtNode(rootControl.helperLayer);
    renderStep(rootControl, currentGuide);
}

/**
 * 事件类型
 * @typedef { 'start' | 'end' | 'next' | 'skip' } EventType
 */

/**
 * @param {EventType} event 事件名称;
 * @param {Function} callback 回调方法;
 */
export function onGuideEvent(event, callback) {
    eventBus.on(event, callback);
}

/**
 * @param {EventType} event;
 * @param {Function} callback;
 */
export function offGuideEvent(event, callback) {
    eventBus.off(event, callback);
}

/**
 * 是否正在runner中
 * @returns {boolean}
 */
export function isRunning() {
    return rootControl.isVisible();
}